import { Injectable } from '@angular/core';
import { Ecole } from 'app/models/ecole.model';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiService } from './api.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EcoleService {
    private _ecole: ReplaySubject<Ecole> = new ReplaySubject<Ecole>(1);

    constructor(private apiService: ApiService) { }

    getAlls(): Observable<any> {
        return this.apiService.get('api/ecole');
    }

    getRegions(): Observable<any> {
        return this.apiService.get('api/region');
    }
    getProvincess(): Observable<any> {
        return this.apiService.get('api/province');
    }
    getCommunes(): Observable<any> {
        return this.apiService.get('api/commune');
    }
    getCebs(): Observable<any> {
        return this.apiService.get('api/ceb');
    }
    getVillages(): Observable<any> {
        return this.apiService.get('api/village');
    }

    // get(id): Observable<Ecole> {
    //     return this.apiService.get('api/ecole/' + id);
    // }

        // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set ecole(value: Ecole)
    {
        // Store the value
        this._ecole.next(value);
    }

    get ecole$(): Observable<Ecole> {
        return this._ecole.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<Ecole>
    {
        return this.apiService.get('api/currentecole').pipe(
            tap((ecole) => {
                this._ecole.next(ecole);
                localStorage.setItem('ecole_db_name', JSON.stringify(ecole.ecole_db_name));
            })
        );
    }

    add(ecole: Ecole): Observable<Ecole> {
        return this.apiService.post('api/ecole', ecole);
    }

    update(ecole: Ecole): Observable<Ecole> {
        return this.apiService.put('api/ecole/' + ecole.id, ecole);
    }

    delete(ecole: Ecole): Observable<any> {
        return this.apiService.delete('api/ecole/' + ecole.id);
    }

}

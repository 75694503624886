<!-- Button -->
<button mat-button [matMenuTriggerFor]="annee" #notificationsOrigin>
    
    {{anneeActive?anneeActive.libelle:'Veuillez choisir'}}
</button>

<!-- Language menu -->
<mat-menu [xPosition]="'before'" #annee="matMenu">
    <ng-container *ngFor="let annee of listeAnnees; trackBy: trackByFn">
        <button mat-menu-item (click)="setActiveAnnee(annee)">
            <span class="flex items-center">
                <span class="ml-3">{{annee.libelle}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

<!-- Flag image template -->
<ng-template let-id #flagImage>
    <span class="relative w-10 shadow rounded-sm overflow-hidden">
         {{id}}
    </span>
</ng-template>
/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Utilisateur } from 'app/models/utilisateur.model';

let user: Utilisateur;
if (localStorage.getItem('user'))
    user = JSON.parse(localStorage.getItem('user'));

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
        // roles: 'ADMIN,USER,SECRETAIRE,DIRECTEUR'
        roles: 'ADMIN,SCOLARITE,SECRETAIRE,DIRECTEUR'
    },
    {
        id: 'scolarite',
        title: 'scolarite',
        type: 'basic',
        icon: 'heroicons_solid:database',
        link: '/scolarite',
        // roles: 'USER,ADMIN,SCOLARITE,DIRECTEUR,SECRETAIRE'
        roles: 'SCOLARITE,DIRECTEUR,SECRETAIRE'
    },
    {
        id: 'pedagogie',
        title: 'pedagogie',
        type: 'basic',
        icon: 'iconsmind:students',
        link: '/pedagogie',
        // roles: 'USER,ADMIN,SCOLARITE,DIRECTEUR,SECRETAIRE'
        roles: 'SCOLARITE,DIRECTEUR,SECRETAIRE'
    },
    {
        id: 'personnel',
        title: 'personnel',
        type: 'basic',
        icon: 'iconsmind:business_manwoman',
        link: '/personnel',
        // roles: 'USER,ADMIN,SCOLARITE,DIRECTEUR'
        roles: 'SCOLARITE,DIRECTEUR'
    },
    // {
    //     id: 'depenses',
    //     title: 'Dépenses',
    //     type: 'basic',
    //     icon: 'iconsmind:money_2',
    //     link: '/depenses',
    //     roles: 'USER,ADMIN,SCOLARITE,DIRECTEUR,SECRETAIRE'
    // },
    {
        id: 'classe',
        title: 'Classes',
        type: 'basic',
        icon: 'heroicons_solid:office-building',
        link: '/classes',
        roles: 'ADMIN'
    },
    {
        id: 'utilisateur',
        title: 'Utilisateurs',
        type: 'basic',
        icon: 'heroicons_solid:users',
        link: '/utilisateur',
        // roles: 'USER,ADMIN,SCOLARITE,DIRECTEUR'
        roles: 'ADMIN,DIRECTEUR'
    },
    {
        id: 'ecole',
        title: 'Ecoles',
        type: 'basic',
        icon: 'heroicons_solid:home',
        link: '/ecoles',
        roles: 'ADMIN, DIRECTEUR'
    },
    {
        id: 'catégorie-matières',
        title: 'Catégorie Matières',
        type: 'basic',
        icon: 'heroicons_solid:academic-cap',
        link: '/pedagogie/categorie-matiere',
        roles: 'ADMIN'
    },
    {
        id: 'matière',
        title: 'Matières',
        type: 'basic',
        icon: 'heroicons_solid:academic-cap',
        link: '/pedagogie/matiere',
        roles: 'ADMIN'
    },
    {
        type: 'divider',
        roles: 'USER,ADMIN,SCOLARITE,DIRECTEUR,SECRETAIRE'
    }
];


defaultNavigation.push(
    {
        type: 'divider'
    }
)

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'pedagogie',
        title: 'pedagogie',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/pedagogie',
    },
    {
        id: 'scolarite',
        title: 'scolarite',
        type: 'basic',
        icon: 'heroicons_solid:database',
        link: '/scolarite',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'pedagogie',
        title: 'pedagogie',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/pedagogie',
    },
    {
        id: 'scolarite',
        title: 'scolarite',
        type: 'basic',
        icon: 'heroicons_solid:database',
        link: '/scolarite',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
    },
    {
        id: 'pedagogie',
        title: 'pedagogie',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/pedagogie',
    },
    {
        id: 'scolarite',
        title: 'scolarite',
        type: 'basic',
        icon: 'heroicons_solid:database',
        link: '/scolarite',
    },
];

const host = location.hostname
console.log(location);
const adresse = location.origin + '/geschool/backend/public/index.php/';
const url: string = location.origin.includes('https://kix-seeds.numerix-technopolis.com')? adresse: '/';
export const environment = {
    production: true,
    // urlApi:"http://localhost/backend/public/index.php/",
    // urlApi:"http://"+host+"/backend/public/index.php/",
    // urlApi:"https://backend.geschool.barasoft-burkina.com/public/index.php/",
    // urlApi:"http://51.178.18.128:8201/"
    // urlApi:"http://geschool-backend.ddns.net/",
    // urlApi:"/"
    // urlApi: url
    urlApi: adresse
};

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnneeScolaire } from 'app/models/annee-scolaire.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private _httpClient: HttpClient) {
    }
    post(url: string, data: any): Observable<any> {
        return this._httpClient.post(environment.urlApi + url + '?annee_id=' + this.activeAnnee?.id + '&ecole_db_name=' + this.ecole_db_name , data);
    }

    put(url: string, data: any): Observable<any> {
        return this._httpClient.put(environment.urlApi + url + '?annee_id=' + this.activeAnnee?.id + '&ecole_db_name=' + this.ecole_db_name , data);
    }
    get(url: string): Observable<any> {
        return this._httpClient.get(environment.urlApi + url + '?annee_id=' + this.activeAnnee?.id + '&ecole_db_name=' + this.ecole_db_name );
    }
    get2(url: string): Observable<any> {
        return this._httpClient.get(environment.urlApi + url + '?annee_id=' + this.activeAnnee?.id + '&ecole_db_name=' + this.ecole_db_name , { responseType: 'blob', reportProgress: true });
    }

    delete(url: string): Observable<any> {
        return this._httpClient.delete(environment.urlApi + url + '?annee_id=' + this.activeAnnee?.id + '&ecole_db_name=' + this.ecole_db_name );
    }

    post2(url: string, data: any): Observable<any> {
        console.log(url)
        return this._httpClient.post(environment.urlApi + url + '?annee_id=' + this.activeAnnee?.id + '&ecole_db_name=' + this.ecole_db_name , data, { responseType: 'blob', reportProgress: true });
    }

    post3(url: string, data: any, options): Observable<any> {
        console.log(url)
        return this._httpClient.post(environment.urlApi + url + '?annee_id=' + this.activeAnnee?.id + '&ecole_db_name=' + this.ecole_db_name , data, options);
    }


    get activeAnnee(): AnneeScolaire {
        if (localStorage.getItem('activeAnnee')) {
            return JSON.parse(
                localStorage.getItem('activeAnnee')
            ) as AnneeScolaire;
        }
    }

    get ecole_db_name() {
        if (localStorage.getItem('ecole_db_name')) {
            return JSON.parse(localStorage.getItem('ecole_db_name'));
        }
    }

}
